/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
/* this file is for public and private/admin pages */
a, .button-link {
  @apply text-blue-500 no-underline;
  transition: color 0.25s ease-in-out;
}

a:hover, a:active, .button-link:hover, .button-link:active {
  @apply underline text-blue-700;
}

a:disabled, .button-link:disabled {
  @apply opacity-75;
}

a:disabled:hover, .button-link:disabled:hover {
  @apply no-underline cursor-default;
}

b, strong {
  @apply font-bold;
}

ul, ol {
  @apply pl-10;
}

ul li, ol li {
  @apply mb-2;
}

ul li:last-of-type, ol li:last-of-type {
  @apply mb-0;
}

ul {
  @apply list-disc;
}

ol {
  @apply list-decimal;
}

address {
  @apply not-italic;
}

td {
  @apply align-top;
}

.transition {
  transition: all 0.25s ease;
}

html {
  @apply font-body leading-normal text-gray-800;
}

.alert {
  @apply flex justify-between mt-0 mx-auto mb-1 p-2 bg-gray-200 border-l-10;
}

.form-wrap .alert {
  @apply w-full;
}

.alert-success {
  @apply border-green-500;
}

.alert-info {
  @apply border-blue-400;
}

.alert-warning {
  @apply border-orange-500;
}

.alert-danger {
  @apply border-red-500;
}

.content-img-center {
  @apply block mx-auto my-4;
}

.content-img-left {
  @apply float-left my-4 mr-4;
}

.content-img-right {
  @apply float-right my-4 ml-4;
}

h1, h2, h3, h4, h5, h6 {
  @apply font-headings leading-tight font-bold;
}

h1 {
  @apply text-5xl;
  margin: 0.67rem 0;
}

h2 {
  @apply text-4xl;
  margin: 0.83rem 0;
}

h3 {
  @apply text-3xl mt-6 mb-2;
}

h4 {
  @apply text-2xl mt-6 mb-2;
}

h5 {
  @apply text-sm;
  margin: 1.67rem 0;
}

h6 {
  @apply text-xs mt-6 mb-2;
}

h3 + p, h4 + p {
  @apply mt-0;
}

h1 a, h2 a, h3 a, h4 a {
  @apply no-underline text-inherit;
}

h1 a:hover, h1 a:active, h2 a:hover, h2 a:active, h3 a:hover, h3 a:active, h4 a:hover, h4 a:active {
  @apply underline text-inherit;
}

p {
  @apply my-4;
}

.herobar {
  @apply py-8 bg-cover bg-right bg-no-repeat;
  background-image: url("/images/protective-sleeve-01-sm.jpg");
}

@screen lg {
  .herobar {
    background-image: url("/images/protective-sleeve-01.jpg");
  }
}

@screen xl {
  .herobar {
    @apply bg-center;
  }
}

.herobar-content {
  @apply py-4;
}

@screen xl {
  .herobar-content {
    @apply pb-2;
  }
}

.herobar-hp {
  @apply bg-white border-t-4 border-b-8 border-gray-900;
}

@screen lg {
  .herobar-hp {
    @apply flex;
  }
}

.herobar_hp-img {
  @apply bg-cover bg-center h-96;
  background-image: url("/images/protective-sleeve-hp-sm.jpg");
}

@screen lg {
  .herobar_hp-img {
    @apply w-3/5;
    background-image: url("/images/protective-sleeve-hp.jpg");
  }
}

.herobar-heading-wrap {
  @apply w-4/5 mx-auto my-8 p-16 bg-blue-800 text-lg text-white;
}

@screen md {
  .herobar-heading-wrap {
    @apply w-3/5 mx-0 text-2xl;
  }
}

.herobar-heading {
  @apply text-3xl mt-0 mb-4 leading-none;
}

@screen lg {
  .herobar-heading {
    @apply text-5xl;
  }
}

.herobar-subheading {
  @apply text-xl mt-2 mb-0 text-yellow-600 leading-normal;
}

@screen lg {
  .herobar-subheading {
    @apply text-3xl;
  }
}

[type=text],
[type=password],
[type=date],
[type=datetime],
[type=datetime-local],
[type=month],
[type=week],
[type=email],
[type=number],
[type=search],
[type=tel],
[type=time],
[type=url],
[type=color],
textarea {
  @apply w-full px-2 py-1 text-black border border-gray-400 rounded-sm bg-gray-200;
  transition: background-color 0.25s ease;
}

[type=text]:focus,
[type=password]:focus,
[type=date]:focus,
[type=datetime]:focus,
[type=datetime-local]:focus,
[type=month]:focus,
[type=week]:focus,
[type=email]:focus,
[type=number]:focus,
[type=search]:focus,
[type=tel]:focus,
[type=time]:focus,
[type=url]:focus,
[type=color]:focus,
textarea:focus {
  @apply border-gray-500 bg-gray-100;
}

textarea {
  @apply align-top text-black;
}

select {
  @apply w-full p-1 text-black border border-gray-400 rounded-sm bg-gray-200;
  height: 2.14rem;
  transition: background-color 0.25s ease;
}

select:focus {
  @apply border-gray-500 bg-gray-100;
}

[type=checkbox] {
  @apply mr-2;
}

[type=radio] {
  @apply relative mr-1;
  top: -1px;
}

[type=submit], [type=image], label, select {
  @apply cursor-pointer;
}

[type=file] {
  width: 100%;
}

.form-wrap {
  @apply w-11/12 max-w-lg mx-auto my-4 p-4 rounded-sm shadow-md;
}

.field-wrap {
  @apply mb-4;
}

.field-wrap label {
  @apply block mb-1;
}

.field-wrap-checkbox {
  @apply flex flex-wrap items-center mb-4;
}

.field-wrap-checkbox label {
  @apply inline-block mr-4 mb-0;
}

.field-wrap-radio {
  @apply mb-1;
}

.field-wrap-radios, .field-wrap-checkboxes {
  @apply mb-4;
}

.field-wrap-radios label, .field-wrap-checkboxes label {
  @apply inline-block mr-4 mb-0;
}

.field-wrap-radios legend, .field-wrap-checkboxes legend {
  @apply mb-1;
}

.field-wrap-radios .field-wrap-checkbox, .field-wrap-checkboxes .field-wrap-checkbox {
  @apply mb-1;
}

.field-errors {
  @apply mb-1 pl-0 list-none;
}

.field-errors li {
  @apply mb-1 p-2 bg-gray-200 border-l-10 border-red-700;
}

.field-wrap-readonly {
  @apply relative;
}

.field-wrap-readonly input {
  @apply opacity-50;
}

.field-wrap-readonly:after {
  @apply absolute right-0 text-xs opacity-50;
  top: 0.5rem;
  content: "read-only";
}

.field-help {
  @apply w-full mt-px text-xs italic text-gray-600 leading-tight;
  padding-left: 0.1em;
}

.form-action {
  @apply text-sm ml-4;
}

.md-enter-active,
.md-leave-active {
  transition: all 0.5s;
}

.md-enter,
.md-leave-active {
  opacity: 0;
  transform: translateY(24px);
}

.w-content, .w-content-narrow, .w-content-copy {
  @apply max-w-11/12 mx-auto;
}

@screen xl {
  .w-content {
    @apply max-w-6xl;
  }
}

@screen sm {
  .w-content-narrow {
    @apply max-w-xl;
  }
}

@screen lg {
  .w-content-narrow {
    @apply max-w-4xl;
  }
}

@screen xl {
  .w-content-narrow {
    max-width: 59.5rem;
  }
}

@screen sm {
  .w-content-copy {
    @apply max-w-xl;
  }
}

@screen xl {
  .w-content-copy {
    @apply max-w-xl;
  }
}

.link-box {
  @apply w-4/5 mx-auto mb-4 p-4 border border-gray-800 text-blue-800 transition-all duration-300 shadow-md bg-gray-100;
}

@screen md {
  .link-box {
    @apply w-56 ml-0 mr-4;
  }
}

.link-box:hover {
  @apply no-underline border-blue-600 shadow-lg bg-white;
}

.link-box h4 {
  @apply text-lg;
}

.bg-d {
  background-image: url("/images/bkg-d.png");
}

.bg-g {
  @apply bg-gray-800;
  background-image: url("/images/bkg-g.png");
}

.section-dark {
  @apply bg-cover bg-center;
  background-image: url("/images/heavy-equipment.jpg");
}

@screen xl {
  .section-dark {
    background-image: url("/images/heavy-equipment.jpg");
  }
}

@screen xl {
  .section-dark {
    @apply bg-fixed;
  }
}

.box-wrap {
  @apply flex flex-col justify-between w-11/12 mb-6 p-8 bg-white text-lg;
  row-gap: 1.5em;
}

@screen md {
  .box-wrap {
    @apply w-128;
  }
}

@screen lg {
  .box-wrap {
    @apply w-12/25;
  }
}

.box-heading {
  @apply mt-0 mb-6 leading-none;
}

button, .button {
  @apply py-3 px-4 text-white bg-blue-800 shadow-none border border-gray-200 rounded leading-tight font-bold;
  transition: all 0.35s ease-in-out;
}

button:hover, .button:hover {
  @apply text-white bg-blue-900 border-blue-800 no-underline;
  transition: all 0.25s ease;
}

button-large, .button-large {
  @apply py-5 px-6 text-lg;
}

@screen lg {
  button-large, .button-large {
    @apply text-xl;
  }
}

button-yellow, .button-yellow {
  @apply text-gray-800 border-white bg-yellow-400;
}

button-yellow:hover, .button-yellow:hover {
  @apply bg-yellow-500 text-gray-800 border-gray-800;
}

a.button {
  @apply inline-block;
}

.header-nav_item {
  @apply hidden order-1 mr-8;
}

@screen md {
  .header-nav_item {
    @apply mr-12 text-lg;
  }
}

@media (min-width: 35rem) {
  .header-nav_item:last-of-type {
    @apply mr-0;
  }
}

.header-nav_item a {
  @apply text-blue-200;
}

.header-nav_item a:hover {
  @apply text-white no-underline;
}

@media (min-width: 1em) {
  .header-nav_item-menu {
    display: inline-block;
  }
}

@media (min-width: 10em) {
  .header-nav_item-inquiries {
    display: inline-block;
  }
}

@media (min-width: 20em) {
  .header-nav_item-id {
    display: inline-block;
  }
}

@media (min-width: 30em) {
  .header-nav_item-design {
    display: inline-block;
  }
}

@media (min-width: 35em) {
  .header-nav_item-weather {
    display: inline-block;
  }
}

@media (min-width: 35em) {
  .header-nav_item-hose {
    display: inline-block;
  }
}

.header-nav_item-menu {
  @apply block mb-0 mr-0 order-2;
}

@media (min-width: 35rem) {
  .header-nav_item-menu {
    @apply hidden;
  }
}

.header_mobile-open ul {
  @apply block mt-6 text-lg;
}

.header_mobile-open a.header-menu:hover, .header_mobile-open a.header-menu:focus, .header_mobile-open a.header-menu:active {
  @apply no-underline text-white;
}

.header_mobile-open .header-nav_item {
  @apply block mr-0 mb-0 border-b border-gray-600 text-center leading-normal;
}

.header_mobile-open .header-nav_item:hover {
  @apply bg-blue-800;
}

.header_mobile-open .header-nav_item a {
  @apply block w-full py-2;
}

.header_mobile-open .header-nav_item-less {
  @apply block pr-2 text-base text-right;
}

.header_mobile-open .header-nav_item-menu {
  @apply hidden;
}

.header_mobile-open .header-nav_item-menu {
  @apply text-right;
}

.color-code {
  @apply w-8 h-8 mr-2;
}

.color-code-black {
  @apply bg-black;
  background-image: url("/images/colors/black.jpg");
}

.color-code-f_orange {
  background-color: #ea3f43;
  background-image: url("/images/colors/flourescent-orange.jpg");
}

.color-code-navy {
  background-color: #54637b;
  background-image: url("/images/colors/navy.jpg");
}

.color-code-jade {
  background-color: #8ef4e0;
  background-image: url("/images/colors/jade.jpg");
}

.color-code-burgandy {
  background-color: #5d3134;
}

.color-code-red {
  background-color: #c72a2e;
  background-image: url("/images/colors/red.jpg");
}

.color-code-purple {
  background-color: #3012b2;
  background-image: url("/images/colors/purple.jpg");
}

.color-code-royal {
  background-color: #1919af;
  background-image: url("/images/colors/royal.jpg");
}

.color-code-grey_dark {
  background-color: #292f42;
  background-image: url("/images/colors/dark-grey.jpg");
}

.color-code-teal {
  background-color: #6ebbda;
  background-image: url("/images/colors/teal.jpg");
}

.color-code-yellow {
  background-color: #fbf519;
  background-image: url("/images/colors/yellow.jpg");
}

.color-code-gold {
  background-color: #de8800;
  background-image: url("/images/colors/gold.jpg");
}

.color-code-green_olive {
  background-color: #857f6b;
  background-image: url("/images/colors/olive.jpg");
}

.color-code-brown {
  background-color: #403735;
  background-image: url("/images/colors/brown.jpg");
}

.color-code-grey {
  background-color: #b0a99e;
}

.color-code-beige {
  background-color: #c8bd8e;
}

.color-code-green_forest {
  background-color: #0e3f11;
}

.banner-hp {
  @apply bg-cover h-96;
  background-image: url("/images/multiuse-sleeve-lg.jpg");
}

@screen lg {
  .banner-hp {
    @apply bg-center h-128;
  }
}

.bg-main {
  background-image: url("/images/protective-sleeve-01.jpg");
}

.bg-hose {
  @apply hidden h-128 bg-contain bg-right-top bg-no-repeat;
  background-image: url("/images/hose-sample-02.png");
}

@screen lg {
  .bg-hose {
    @apply block w-1/2;
  }
}

@tailwind utilities;
